// Font variables
$font-primary: 'Inter', sans-serif; // For headings
$font-secondary: 'Manrope', sans-serif; // For body text

// Custom color variables
$mint-green: #3EB489;
$mint-soft: #6FC8A7;
$dark-gray: #1E1E1E;
$deep-black: #121212;
$light-grey: #F6F6F6;
$white: #FFFFFF;

// Bootstrap overrides
$primary: $mint-green;
$secondary: $mint-soft;
$dark: $white;
$light: $deep-black;
$body-bg: $dark-gray;

// Custom variables
$navbar-blur: rgba($deep-black, 0.9);
$transition-normal: all 0.3s ease;

// Import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// Import Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Manrope:wght@300;400;500;600;700;900&display=swap');

// Add Bootstrap Icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.0/font/bootstrap-icons.css");

// Global styles
html {
  scroll-behavior: smooth;
}

body {
  color: $white;
  font-family: $font-secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
}

// Progress bar
.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  background: $mint-soft;
  z-index: 999;
  transition: width 0.1s ease;
}

// Floating navbar styles
.floating-nav {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  background: rgba($deep-black, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba($white, 0.1);
  border-radius: 100px;
  padding: 0rem 0.35rem;
  width: auto; // Allow natural width on desktop
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 0;

  .desktop-nav {
    display: flex;
    align-items: center;

    a {
      color: $white;
      text-decoration: none;
      padding: 0.5rem 1rem;
      transition: $transition-normal;
      font-size: 1rem;
      font-weight: 300;

      &:hover {
        color: $mint-green;
      }
    }
  }

  .navbar-brand {
    font-family: $font-primary;
    font-weight: 700;
    letter-spacing: -0.02em;
    font-size: 1.08rem;
    color: $white;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }

  .nav-cta {
    background: $mint-green;
    border-radius: 100px;
    color: $deep-black;
    font-weight: 600;
    margin-left: 0.5rem;

    &:hover {
      background: darken($mint-green, 5%);
      color: $deep-black !important;
    }
  }

  // Mobile navigation container
  .mobile-nav-container {
    display: flex; // Changed from 'none' to always show the brand
    width: auto; // Changed from 100% to auto for desktop
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.35rem;
  }

  // Mobile menu toggle button
  .mobile-menu-toggle {
    display: none; // Hidden by default on desktop
    background: none;
    border: none;
    color: $white;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    svg {
      width: 24px;
      height: 24px;
      display: block; // Show icons by default

      &.hidden {
        display: none; // Only hide when .hidden class is present
      }
    }
  }

  // Mobile menu
  .mobile-menu {
    display: none;
    position: absolute;
    bottom: calc(100% + 0.5rem); // Position below the header with some spacing
    left: 0;
    width: 100%;
    background: rgba($deep-black, 0.8);
    backdrop-filter: blur(10px);
    border: 1px solid rgba($white, 0.1);
    border-radius: 1rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    z-index: 1000;
    /* Ensure it’s above other elements */
    width: 100%;
    /* Adjust width as needed */

    a {
      display: block;
      padding: 0.75rem 1rem;
      color: $white;
      text-decoration: none;
      font-size: 1rem;
      border-radius: 0.5rem;

      &:hover {
        color: $mint-green;
        background: rgba($white, 0.1);
      }
    }

    &.hidden {
      display: none;
    }

    &:not(.hidden) {
      display: block;
    }
  }
}

// Floating Nav Mobile-specific styles
@media (max-width: 768px) {
  .floating-nav {
    bottom: 1rem !important;
    top: auto !important;
    width: calc(100% - 2rem);
    max-width: 300px;
    padding: 0;

    .nav-links {
      flex-direction: column;
      width: 100%;

      .desktop-nav {
        display: none;
      }

      .mobile-nav-container {
        display: flex;
        width: 100%; // Full width on mobile
      }

      .mobile-menu-toggle {
        display: block;
        padding: 0.5rem 1rem;
      }

      .mobile-menu {
        &.hidden {
          display: none;
        }

        &:not(.hidden) {
          display: block;
        }
      }
    }
  }

  // Mobile language selector styles
  .mobile-language-selector {
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    border-top: 1px solid rgba($white, 0.1);

    .language-toggle {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: none;
      border: none;
      color: $white;
      width: 100%;
      padding: 0.75rem 1rem;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .mobile-language-menu {
      padding: 0.5rem 0;

      &.hidden {
        display: none;
      }

      a {
        padding: 0.75rem 1rem;

        &.active {
          color: $mint-green;
        }
      }
    }
  }
}

// Top Fixed Navbar
.navbar {
  transition: background-color 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.5s ease 1.7s forwards;
  padding: 1rem 0;
  //background: $navbar-blur;
  //backdrop-filter: blur(10px);
  transition: $transition-normal;
  //border-bottom: 1px solid rgba($white, 0.1);

  .navbar-brand {
    font-family: $font-primary;
    font-weight: 800;
    letter-spacing: -0.02em;
    color: lighten($deep-black, 10%);
  }

  .nav-link {
    color: $white !important;
    position: relative;
    padding: 0.5rem 1rem;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 50%;
      background-color: $mint-soft;
      transition: $transition-normal;
      transform: translateX(-50%);
    }

    &:hover::after {
      width: 100%;
    }
  }
}

// Name Banner
.name-banner {
  position: absolute;
  top: 50px;
  font-family: $font-primary;
  font-weight: 800;
  letter-spacing: -0.02em;
  color: $white;
  font-size: 2.2rem;
  text-align: left;
  z-index: 10;
  color: $dark-gray;
  padding: 0 1rem;

  p {
    font-family: $font-secondary;
    font-size: 1.2rem;
    font-weight: 400;
  }

}

// Hero section
.hero-section {
  background-image: url('/assets/edward-tung-iceland-mountains.webp');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: start;
  //background: radial-gradient(circle, #cccccc 50%, #b3b3b3 100%);
  //background-color: #cccccc;
  position: relative;
  overflow: hidden;

  h1 {
    color: $white;
    position: relative; // This helps with z-index stacking

    span {
      color: $mint-green;
    }
  }
}

.hero-content {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease 1.6s forwards;
  position: relative;
  z-index: 1; // Ensures text is above the background but behind the image
}

.hero-image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35%; // Adjust based on your needs
  max-width: 600px; // Adjust based on your image
  z-index: 2;
  opacity: 0;
  transform: translateX(30px);
  animation: fadeInRight 0.8s ease 1.8s forwards;

  .hero-portrait {
    width: 100%;
    height: auto;
    filter: grayscale(100%) contrast(0.9) brightness(1.2); // Makes the image black and white
    mix-blend-mode: luminosity; // This helps blend the B&W effect with the background
    opacity: 0.9; // Slightly reduces opacity to blend better
  }
}

// Responsive adjustments
@include media-breakpoint-down(xl) {
  .hero-image {
    width: 45%;
  }

  .hero-content {
    text-align: left; // Keep text left-aligned even on mobile
    padding-right: 2rem; // Gives some space from the image
  }
}

@include media-breakpoint-down(lg) {
  .hero-image {
    width: 55%;
  }

  .hero-content {
    text-align: left; // Keep text left-aligned even on mobile
    padding-right: 2rem; // Gives some space from the image
    z-index: 99;
    top: 17vh;
  }
}

@include media-breakpoint-down(md) {
  .hero-image {
    width: 65%;
  }

  .hero-content {
    padding-right: 1rem;
    z-index: 99;
  }
}

@include media-breakpoint-down(sm) {
  .hero-image {
    width: 80%;
  }

  .hero-content {
    z-index: 99;
  }
}

// Brand Logos Section
#brand-logos {
  background-color: $light-grey;
  overflow: hidden;

  h3 {
    font-size: 1rem;
    color: $dark-gray;
    font-weight: 400;
  }

  .logo-scroll {
    position: relative;
    overflow: hidden;
    padding: 20px 0 30px; // Keeps desktop padding
    margin: 0 -20px;
  }

  .logo-track {
    display: flex;
    white-space: nowrap;
    will-change: transform;
    user-select: none;
    -webkit-user-select: none;
    gap: 20px;
    padding: 0 20px;
  }

  #logoTrack {
    transition: transform 0.1s linear;
  }

  .logo-wrapper {
    flex: 0 0 auto;
    text-decoration: none;
    color: inherit;
    position: relative;
    width: 120px;
  }

  .logo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    img {
      max-width: 100%;
      max-height: 50px;
      opacity: 0.7;
      filter: grayscale(100%);
      transition: all 0.3s ease;
    }

    .logo-label {
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: all 0.3s ease;
      font-size: 0.75rem;
      text-align: center;
      white-space: nowrap;
      color: $dark-gray;
      padding: 4px 8px;
    }

    &:hover {
      img {
        opacity: 1;
        filter: none;
        transform: translateY(-2px);
      }

      .logo-label {
        opacity: 0.8;
        bottom: -30px;
      }
    }
  }

  @media (max-width: 768px) {
    .logo-scroll {
      padding: 20px 0; // Reduced bottom padding on mobile
    }

    .logo-wrapper {
      width: 120px; // Made wider for bigger logos
    }

    .logo-item {
      img {
        max-height: 50px;
        opacity: 0.85;

        &:active {
          opacity: 1;
          filter: none;
          transform: translateY(-1px);
        }
      }
    }

    .logo-track {
      gap: 0px;
    }

    // Simple touch feedback without label
    .logo-wrapper:active {
      .logo-item img {
        opacity: 1;
        filter: none;
      }
    }
  }

  @include media-breakpoint-down(md) {

    // Hide label by default on mobile
    .logo-label {
      display: none; // Hide the labels on mobile
    }
  }

}

// About Section
#about {
  padding: 5rem 0;

  p {
    color: $dark-gray;
    font-weight: 400;
  }

}

// Services section
.services-section {
  padding: 5rem 0;
  background-color: $light-grey;

  h3 {
    font-weight: 600;
    color: $dark-gray;
  }

  .service-card {
    height: 100%;
    background: lighten($light-grey, 10%);
    border-radius: 1rem;
    overflow: hidden;
    transition: $transition-normal;
    border: 1px solid rgba($white, 0.1);
    color: $dark-gray;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba($white, 0.08);
    }

    .service-content {
      padding: 1.5rem;

      .service-icon {

        i {
          color: #3EB489;
        }

      }

    }

    h3 {
      font-size: 1.1rem;
      color: $dark-gray;
    }

    p {
      color: $dark-gray;
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
    }

    a {
      font-size: 0.875rem;
    }
  }
}

// Contact section
.contact-section {
  padding: 5rem 0;
  background-color: $dark-gray;

  h3 {
    font-weight: 600;
    color: $dark-gray;
  }

  .social-links {
    display: flex;
  }

  .social-link {
    display: flex;
    align-items: center;
    border-radius: 50%;
    color: $white;
    transition: $transition-normal;

    &:hover {
      color: $mint-green;
      transform: translateY(-2px);
    }
  }
}

// Custom button styles
.btn-primary {
  &:hover {
    background-color: darken($mint-green, 5%);
    border-color: darken($mint-green, 5%);
  }
}

.btn-outline-dark {
  color: $white;
  border-color: $white;

  &:hover {
    background-color: $white;
    border-color: $white;
  }
}

// Responsive adjustments
@include media-breakpoint-down(lg) {
  .hero-section {
    text-align: center;

    .btn-group {
      justify-content: center;
    }
  }
}

// Loading Animation
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $deep-black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  pointer-events: none;

  .loader-content {
    width: 80%;
    max-width: 400px;
    text-align: center;
    position: relative;
  }

  .loader-name-container {
    position: relative;
    overflow: hidden;
    height: 60px; // Return to original height
    margin-bottom: 1rem;
    
    @media (max-width: 768px) {
      height: 50px;
    }
  }

  .loader-name {
    color: $white;
    font-family: $font-primary;
    font-size: 2.6rem;
    font-weight: 800;
    letter-spacing: -0.02em;
    position: absolute;
    width: 100%;
    bottom: 0; // Position from bottom instead of top
    transform: translateY(100%);
    animation: slideUpName 0.6s ease 0.5s forwards;
    
    span {
      color: $mint-green;
    }
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .loader-line-container {
    position: relative;
    height: 2px;
    background: rgba($white, 0.1);
    overflow: hidden;
    margin-bottom: 1rem;
    transform: translateY(-40px);
    opacity: 0;
    animation: slideDownBar 0.5s ease 0.8s forwards;

    .loader-line {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $mint-green;
      transform-origin: left;
      transform: scaleX(0);
      animation: loading 1s ease 1s forwards;
    }
  }

  .loader-percentage {
    color: rgba($white, 0.7);
    font-size: 0.9rem;
    font-weight: 500;
    display: block;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeInPercent 0.4s ease 1.1s forwards;
  }
}

@keyframes slideUpName {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideDownBar {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes fadeInPercent {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

// Text animations
.animate-text {
  overflow: hidden;

  .text-line {
    display: block;
    opacity: 0;
    transform: translateY(100%);
  }
}

// Button animations
.animate-buttons {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease 1.6s forwards;
}

// Image animation
.animate-image {
  opacity: 0;
  transform: translateX(50px);
}

// Floating nav animation
.floating-nav {
  opacity: 0;
  transform: translate(-50%, 20px);
  animation: navFadeIn 0.6s ease 2s forwards;

  @media (max-width: 768px) {
    &.locked {
      top: 5rem !important; // Gives space for fixed navbar on mobile
    }
  }
}

// Animation keyframes
@keyframes loading {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes navFadeIn {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

// Language Dropdown
.language-dropdown {

  /* Dropdown Toggle Button */
  .dropdown-toggle {
    border: none;
    border-radius: 30px;
    /* Rounded button */
    background-color: #ffffff;
    /* White background */
    color: #000000;
    /* Black text */
    font-size: 14px;
    /* Adjust text size */
  }

  .dropdown-toggle:focus {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Subtle focus effect */
    outline: none;
  }

  /* Dropdown Menu */
  .dropdown-menu {
    border: none;
    background-color: #ffffff;
    /* White background */
    border-radius: 12px;
    /* Rounded dropdown */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
  }

  /* Dropdown Items */
  .dropdown-menu .dropdown-item {
    color: #000000;
    /* Black text */
    border-radius: 8px;
    /* Rounded menu items */
    padding: 8px 12px;
    /* Adjust spacing */
  }

  .dropdown-menu .dropdown-item:hover {
    background-color: #f8f9fa;
    /* Light gray hover effect */
    color: #000000;
    /* Ensure text remains black on hover */
  }

  /* Active Dropdown Item */
  .dropdown-menu .dropdown-item.active {
    background-color: #e9ecef;
    /* Highlighted active color */
    font-weight: bold;
    border-radius: 8px;
    /* Rounded active item */
    color: #000000;
    /* Ensure active text is black */
  }

  /* Active Checkmark */
  .dropdown-menu .dropdown-item i {
    font-size: 1rem;
    color: #0d6efd;
    /* Primary color for checkmark */
  }

  .dropdown-menu .dropdown-item.active i {
    color: #0d6efd;
    /* Keep checkmark visible for active item */
  }

  .dropdown-toggle::after {
    display: none;
  }

}

.hidden {
  opacity: 0;
  visibility: hidden;
  display: none !important;
}

// Portfolio Section Styles
.portfolio-section {
  background-color: #fff;
  padding: 5rem 0;

  h2 {
    font-weight: 600;
    color: $dark-gray;
  }
}

// Filter Buttons
.portfolio-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.filter-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  background: transparent;
  color: #666;
  font-size: 0.9rem;
  transition: all 0.3s ease;

  &:hover {
    background: #f5f5f5;
    color: #333;
  }

  &.active {
    background: #333;
    color: #fff;
    border-color: #333;
  }
}

// Portfolio Grid
.portfolio-card {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &.linked {
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

      //.external-link {
      //  opacity: 1;
      //}
    }
  }
}

.portfolio-image {
  position: relative;
  overflow: hidden;
  padding-top: 75%; // 4:3 aspect ratio

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.25rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), transparent);
  color: #fff;

  h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #fff;
    font-weight: 600;
  }

  p {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    opacity: 0.9;
    line-height: 1.4;
  }
}

// External Link Icon
.external-link {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  padding: 0.5rem;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Tags
.portfolio-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.35rem;
}

.tag {
  font-size: 0.55rem;
  padding: 0.15rem 0.5rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.02em;
}

// Media Queries
@media (max-width: 768px) {
  .portfolio-filters {
    justify-content: center;
  }

  .filter-btn {
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
  }

  .card-content {
    h3 {
      font-size: 1.1rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
}

// Add a nice fade-in animation
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.portfolio-item {
  animation: fadeIn 0.5s ease forwards;
}

// CTA SECTION
.cta-section {
  background-color: #FFFFFF;
  padding: 5rem 0;
  color: $dark-gray;
}

.cta-section .btn {
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  font-weight: 500;
}

.cta-section .btn-primary {
  background-color: $mint-green;
  border-color: $mint-green;
  color: $white;
}

.cta-section .btn-primary:hover {
  background-color: darken($mint-green, 5%);
  border-color: darken($mint-green, 5%);
  color: $deep-black !important;
}

.cta-section {
  .btn-outline-dark {
    color: $dark-gray;
    border-color: $dark-gray;

    &:hover {
      background-color: $dark-gray;
      border-color: $dark-gray;
      color: $white;
    }
  }
}